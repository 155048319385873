/* Add these styles to your CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* 
input[type="date"]::-webkit-calendar-picker-indicator {
    width: 24px;  
    height: 24px; 
    color: white !important; 
    background-color: white; 
    border-radius: 10%;  
  }
   */

.swiper-wrapper {
  display: flex !important;
}

.rules-bx {
  display: block;
  width: 100%;
  padding-top:0 ;
  padding-left: 20px;
  padding-right: 20px;
 
}

.rules-bx h1,
.rules-bx h2 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 16px;
  margin-top: 0;
  color: #000;
  font-weight: 600;
}

.rules-bx h2 {
  padding: 8px 0 4px;
}

.rules-bx ul li {
  line-height: 20px  !important;
  font-size: 14px !important;
  list-style-type: disc !important;
  padding-bottom: 8px !important;
  margin-left: 50px;

  
}