/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Signika:wght@300..700&display=swap');

* {font-family: 'Poppins', sans-serif;}

html {
    scroll-behavior: smooth;
    height: 100%;
    margin: 0;
    padding: 0;}
    

.colour_back_odds {background-color: white !important;}

.page_bg {background-color: white !important;}

.white-bg {background-color: #f7f7f7;}

.card-border {border: 1px solid #dee2e6 !important;}

.white-text {color: white;}

.black-text {
    color: black;
}

.detail-header {
    background-color: #24e6a4;
}

.red-header {
    background-color: #24e6a4;
}

.rules-header {
    background-color: #24e6a4
}

.gray-color {
    background-color: #F2F2F2;
}

.gray-text {
    color: #303030;
}

.player-bg {
    background-color: black;
    color:white !important;
}

.tab-color {
    background-color: #ff9601;
}

.detail-header {
    background-color: #2c3e50 !important;
}

.grey-color {
    background-color: #eeeeee;
}

.odds-lgaai {
    background-color: #a7d8fd;
}

.back1 {
    background-color: rgba(114, 187, 239, 0.75);
}

.back2 {
    background-color: rgba(114, 187, 239, 0.5);
}

.odds-khaii {
    background-color: #FAA9BA;
}


.lay1 {
    background-color: rgba(250, 169, 186, 0.75);
}

.lay2 {
    background-color: rgba(250, 169, 186, 0.5);
}


.even-background {
    background: linear-gradient(to right, #702802, #24e6a4);
}

.blue-bg{
    background-color: #086cb8;
}

.odds-bet {
    /* border: 4px solid #FAE700; */
    background-color: #EEEEEE;
}

.result-color {
    background-color: #028833;
}

.odds-background {
    background: linear-gradient(to right, #bf9c40, #7b0b00) !important;
}

.border-bottom {
    border: 1px solid #c7c8ca;
}

.dark-blue {
    background-color: #2E4B60;
}

.grey-bg {
    background-color: #FFFFFF;
}

.tag-name {
    background: linear-gradient(to right, #ff9601, #d0012f);
}

.worli-color {
    background-color: #CCCCCC;
}

.font-text {
    font-family: CasinoNumFont !important;
}

.dark-grey {
    background-color: #2E3439;
}

.light-text {
    color: #aaafb5;
}

.light-blue {
    background-color: #72BBEF;
}

.light-grey {
    background-color: #3c444b85;
}

.black-background {
    background-color: (0, 0, 0, .6);
}

.blue-bg {
    background-color: #72bbef;
}

.pink-bg {
    background-color: #faa9ba;
}

.Andar-bg {
    background-color: lightsalmon;
}

.Bahar-bg {
    background-color: lightgreen;
}

.darktext {
    color: #333;
}

.border-blue {
    border: 2px solid #72bbef
}

.border-pink {
    border: 2px solid #f994ba;
}

.border-blue2 {
    border: 1.5px solid #72bbef
}

.border-pink2 {
    border: 1.5px solid #f994ba;
}

.name-color {
    background-color: #3c444b;
}

.blue-bg {
    background: linear-gradient(90deg, rgb(28 55 125) 0, rgb(41 75 159) 20%, rgb(39 87 187) 40%, rgb(37 88 188) 60%, rgb(44 82 171) 80%, rgb(34 55 117) 100%);
}

.tean-color {
    background-color: rgba(0, 0, 0, 0.5);
}


.score-box {
    position: relative;
    height: 60px;
    margin-top: 30px;
    padding: 0;
    background-image: url(../Casino//images/score-bg-cricket.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 45px;
}

/* .score-box1 {
    position: relative;
    background-image: url(../Casino//images/score-bat-icon.png);
    margin-bottom: 40px;
    padding: 0 !important;
    height: 70px;
    border-radius: 0 !important;
}

.btn-theme {
    background: linear-gradient(to right, #ff9601, #d0012f);
    color: #fff;
    height: 70px;
    padding: 6px;
    border: 0;
    box-shadow: none;
    outline: 0;
    font-size: 14px;
    width: 100%;
    position: relative;
} */

.suspended {
    position: relative;
    display: block !important;
    width: 100%;
}

.score-box2 {
    position: relative;
    height: 70px;
    margin-bottom: 40px;
    padding: 0 !important;
    border-radius: 0 !important;
}

.btn-theme {
    background-image: linear-gradient(to right, #ff9601, #d0012f);
    color: #fff;
    padding: 6px;
    border: 0;
    box-shadow: none;
    outline: 0;
    font-size: 14px;
    width: 100%;
    position: relative;
}

.ball-image {
    width: 50px;
    position: absolute;
    top: 40px;
    left: 10px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

img {
    vertical-align: middle;
    border-style: none;
}

.score-img {
    width: 70%;
}

.team-score {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    display: flex;
    align-items: center;
    height: 50px;
    left: 20%;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.min-max {
    position: absolute;
    top: 53px;
    right: 5px;
    line-height: 1;
    padding-left: 5px;
    font-size: 12px !important;
    font-weight: 400;
}

.backbox {
    position: absolute;
    top: 0;
    left: 70%;
    width: 15%;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.back {
    background-color: #a7d8fd !important;
}

.odds {
    color: black;
    font-weight: bold;
}

.laybox {
    position: absolute;
    top: 0;
    left: 85%;
    width: 15%;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lay {
    background-color: #f9c9d4 !important;
}

.text-red-color {
    color: #FF0000;
}