.borderye {
    border: #C99D1E;
    border-radius: 2px;
    background: #f9f9f9;
    border: solid 2px #c99d1e;
    padding: 5px;
    margin-bottom: 5px;
}

.state-name {
    background: black;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.state-profit-loss {
    display: flex;
    justify-content: space-between;
    white-space: initial;
    gap: 50px;
    font-size: 14px;
}

.state-profit-loss>span:nth-child(1) {
    font-weight: 700;
}

.state-profit-loss>span:nth-child(2),
.state-profit-loss>span:nth-child(3) {
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.odd-even {
    display: flex;
    justify-content: space-around;
    /* background: white;betprice */
}

.odd-even>span {
    font-weight: 700;
}

.betprice-box {
    padding: 5px;
}

.betprice-box>div {
    background: #232323;
    color: white;
    /* margin: 5px; */
    padding: 8px 20px;
    width: 100%;
    height: 100%;
}

.clearbtn {
    background: #E61414 !important;
    color: white;
    margin: 10px;
    padding: 8px 15px;
    width: 100%;
    height: 35px;
    text-align: center;
}

.spacing {
    display: flex;
    justify-content: center;
}

.clear-all-btn {
    background-color: red;
    color: white;
    border: none;
    font-size: 15px;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    margin: 2px;
    padding: 5px;
}

.place-bet-btn {
    background-color: #0b7d36;
    color: white;
    border: none;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    margin: 2px;
    padding: 5px;
    font-size: 15px;
}

.suspended {
    width: 100%;
    color: red;
    display: block;
}

/* .odd-input,
.even-input {
    width: 50%;
    height: 50px;
    margin: 5px;
    border: 2px solid white !important;
    padding: 5px 15px;
    border: #0b7d36;
} */

@media screen and (max-width:1023px) {
    .position {
        /* display: none; */
        /* position: fixed; */
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .borderye {
        position: fixed;
        /* top: 50%; */
        bottom: 15%;
        left: 20%;
        transform: translate(-10%, -20%);
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
    }

    .position.active {
        display: block;
        pointer-events: none;
    }
}

@media screen and (max-width:766px) {
    .position {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .borderye {
        position: fixed;
        /* top: 50%; */
        bottom: -15%;
        left: 70%;
        transform: translate(-70%, -70%);
        background-color: #fff;
        padding: 5px;
        border-radius: 5px;
    }

    .position.active {
        display: block;
        pointer-events: none;
    }

    .odd-input,
    .even-input {
        margin: 2px;
        padding: 5px 12px;
    }

    .clearbtn {
        margin: 10px;
        padding: 10px 15px;
        width: 25%;
    }
}

.App {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
}

.countdown-container .timer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    /* Ensures the content is circular */
    background-color: rgba(255, 255, 255, 0.1);
    /* Semi-transparent background */
}

.countdown-container svg {
    border-radius: 5px;
}

.borderye {
    border: #C99D1E;
    border-radius: 2px;
    background: #f9f9f9;
    border: solid 2px #c99d1e;
    padding: 5px;
    margin-bottom: 5px;
}

.state-name {
    background: black;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.state-profit-loss {
    display: flex;
    justify-content: space-between;
    white-space: initial;
    gap: 50px;
    font-size: 14px;
}

.state-profit-loss>span:nth-child(1) {
    font-weight: 700;
}

.state-profit-loss>span:nth-child(2),
.state-profit-loss>span:nth-child(3) {
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.odd-even {
    display: flex;
    justify-content: space-around;
    /* background: white;betprice */
}

.odd-even>span {
    font-weight: 700;
}

.betprice-box {
    padding: 5px;
}

.betprice-box>div {
    background: #232323;
    color: white;
    /* margin: 5px; */
    padding: 8px 20px;
    width: 100%;
    height: 100%;
}

.clearbtn {
    background: #E61414 !important;
    color: white;
    margin: 10px;
    padding: 8px 15px;
    width: 100%;
    height: 35px;
    text-align: center;
}

.spacing {
    display: flex;
    justify-content: center;
}

.clear-all-btn {
    background-color: red;
    color: white;
    border: none;
    font-size: 15px;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    margin: 2px;
    padding: 5px;
}

.place-bet-btn {
    background-color: #0b7d36;
    color: white;
    border: none;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    margin: 2px;
    padding: 5px;
    font-size: 15px;
}

.suspended {
    width: 100%;
    color: red;
    display: block;
}

/* .odd-input,
.even-input {
    width: 50%;
    height: 50px;
    margin: 5px;
    border: 2px solid white !important;
    padding: 5px 15px;
    border: #0b7d36;
} */

@media screen and (max-width:1023px) {
    .position {
        /* display: none; */
        /* position: fixed; */
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .borderye {
        position: fixed;
        /* top: 50%; */
        bottom: 15%;
        left: 20%;
        transform: translate(-10%, -20%);
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
    }

    .position.active {
        display: block;
        pointer-events: none;
    }
}

@media screen and (max-width:766px) {
    .position {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .borderye {
        position: fixed;
        /* top: 50%; */
        bottom: -15%;
        left: 70%;
        transform: translate(-70%, -70%);
        background-color: #fff;
        padding: 5px;
        border-radius: 5px;
    }

    .position.active {
        display: block;
        pointer-events: none;
    }

    .odd-input,
    .even-input {
        margin: 2px;
        padding: 5px 12px;
    }

    .clearbtn {
        margin: 10px;
        padding: 10px 15px;
        width: 25%;
    }
}

.App {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
}

.countdown-container .timer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    /* Ensures the content is circular */
    background-color: rgba(255, 255, 255, 0.1);
    /* Semi-transparent background */
}

.countdown-container svg {
    border-radius: 5px;
}




.bet-header {
    background-color: #CCCCCC;
}

.betdetail-header {
    background-color: #72BBEF;
}

.last-header {
    background-color: rgb(75, 91, 106);
}

.table-header {
    background-color: #CCCCCC;
}

.border-color {
    border: 1px solid #CCCCCCCC;
}

.tab-color {
    background-color: #ff9601;
}

.red-color {
    background-color: #E61414;
}

.match_bg_blue_index-0 {
    background-color: #72BBEF;
}

.match_bg_pink_index-0 {
    background-color: #FAA9BA;
}

.dark-text {
    color: #343a40;
}

.light-blue {
    background-color: #72BBEF;
}

.light-pink {
    background-color: #FAA9BA;
}

/* .pink-bg{
    background-color: #d0012f;
} */

/* .bg-lock{
    background-color: rgba(0, 0, 0, 0.6);
} */

.super-over-rule-img {
    height: 40px;
}

table th,
.table td {
    padding: 2px 8px;
    vertical-align: middle;
}

img,
svg {
    vertical-align: middle;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: var(--table-border);
}


.text-end {
    text-align: right !important;
}

.slick-prev,
.slick-next {
    background-color: white !important;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.slick-prev::before,
.slick-next::before {
    color: black !important;
    font-size: 15px;
}