@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  background: white;

}
:root {
  --back: #868ff9;
  --lay: #ff8ca6;
  --login-background: #0070c0;
  --color1: #4049d0;
  --color2: #303030;
  --color3: #1d2d3d;
  --color4: #1d2d3d;
  --black: #000;
  --light-black: #252525;
  --grey: #f1f1f1;
  --dark-grey: #e6e6e6;
  --matchbook-color: #1d2d3d;
  --bk-black: #000;
  --bk-light-green: #5DAE32;
  --blue: rgba(0, 8, 73, 0.9);
  --bk-light-blue: rgb(229 231 252 / 90%);
}
.hideScrollbar {
    overflow: auto; /* Hides the scrollbar */
    scrollbar-width: none; /* For Firefox */
  }
  
  /* For WebKit browsers (Chrome, Safari) */
  .hideScrollbar::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }

  ::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
  }


  .market-info:not(.bottom-radius) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}


.market-info {
  padding: 1px 1px;
  background-color: #252322;
}

.position-relative {
  position: relative !important;
}



.justify-content-between {
  justify-content: space-between !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.match-board-tournament-name {
  position: absolute;
  left: 0;
  right: 0;
  top: -10.5px;
  display: flex;
  justify-content: center;
}

.match-board-tournament-name span.t-name {
  position: absolute;
  text-align: center;
  line-height: 32px;
  height: 40px;
  top: -8px;
  background: #e4440e;
  z-index: 1;
  color: black;
  text-transform: uppercase;
  padding: 0 10px;
  width: 70%;
  clip-path: polygon(0% 0%, 2% 100%, 98% 100%, 100% 0%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
}

.match-board-tournament-name span.right-icon {
  position: absolute;
  z-index: 0;
  background: gray;
  clip-path: polygon(27% 100%, 100% 100%, 100% 0%);
  height: 18px;
  width: 2%;
  right: 15%;
  top: -7px;
}
.match-board-tournament-name span.left-icon {
  position: absolute;
  z-index: 1;
  background: gray;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
  height: 18px;
  width: 2%;
  left: 15%;
  top: -7px;
}

.date-tour {
  color: #e4440e;
  z-index: 1;
  position: absolute;
  top: 15px;
  font-size: 11px;
}

.market-tour-c {
  color: #e4440e;
  position: absolute;
  top: 22px;
}

.market-info .titles {
  text-align: center;
  justify-content: center;
  font-size: 15px;
  padding: 5px 0;
  font-weight: 600;
  text-transform: uppercase;
}

.remain-time {
  height: 25px;
  text-align: left;
  margin: 8px;
  color: red;
}

.match-poster {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  position: relative;
}







.loaderBet, .loaderBet:before, .loaderBet:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loaderBet {
  color: #f15a24;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loaderBet:before,
.loaderBet:after {
  content: '';
  position: absolute;
  top: 0;
}
.loaderBet:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loaderBet:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
    
.sidebarbg{
  /* background: linear-gradient(132deg, #1D1D1D 8.33%, #1B1B1B 8.33%, #1B1B1B 50%, #1D1D1D 50%, #1D1D1D 58.33%, #1B1B1B 58.33%, #1B1B1B 100%); */
}
@keyframes widgetPulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;

  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
   
  }
  100% {
    transform: scale(2, 2);
    opacity: 0;
   
  }
}



.widget-pulse {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #00aeef;
  border-radius: 50%;
  border-color: #ffa801;
  animation: widgetPulse 1.5s infinite;
}


.see-all-btn{background: linear-gradient(to top,#191a25 0%,#292b3a 100%)}


.account-sec:hover svg{color:#f97316;}



#chat-widget-container{
 bottom: 60px !important;
 max-height: 85% !important;
 max-width: 85% !important;
}


/* /////////////////////////////////////////////////////////////////////////////////////// */
.sidebarbx:hover {
  background: var(--blue);
  color: #fff;
  
}
.sidebarbx  {
  font-size: 12px;
  color: var(--blue);
  padding: 8px 10px;
  line-height: 1.6;
  font-weight: 600;
  position: relative;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
  text-transform: capitalize;
  border-bottom: 1px solid #ccccccb0 !important;
}

.submenu {
font-weight: 400 !important;
  color: #222 !important;
  font-size: 11px !important;
 
}

